<template>
  <div v-if="phone">
    <div v-if="isSuceess" class="phone-success" :class="isPointer ? 'hover-dom' : ''" @click="handlePhoneClick('success')">
      <i class="phone-icon-box"></i><span>{{ phone }}</span>
    </div>
    <template v-else>
      <el-tooltip
        v-if="tips"
        class="item"
        effect="dark"
        :content="tips"
        placement="bottom"
      >
        <div class="phone-error hover-dom" @click="handlePhoneClick('error')"><i class="phone-icon-box"></i><span>{{ phone }}</span></div>
      </el-tooltip>
      <div v-else class="phone-error" @click="handlePhoneClick('error')"><i class="phone-icon-box"></i><span>{{ phone }}</span></div>
    </template>
  </div>
</template> 
<script>
export default {
  props: {
    phoneType: {
      default: ''
    },
    isSuceess: {
      // 拨打是否成功
      type: Boolean,
      default: false,
    },
    phone: {
      // 电话号码
      type: [String, Number],
      default: "",
    },
    tips: {
      // 拨打失败，备注信息
      type: String,
      default: "",
    },
    isPointer: {
      // 有效电话  是否有小手掌图标
      type: Boolean,
      default: false
    },
    isBack: {
      // 点击有效、无效电话  是否发起回调
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {};
  },
  methods: {
    handlePhoneClick(type) {
      if (!this.isBack) return;
      this.$emit('back', { type: type, row: this.row, phone: this.phone, phoneType: this.phoneType })
    }
  }
};
</script>
<style lang="stylus" scoped>
.phone-icon-box {
  display: inline-block;
  width: 12px;
  height: 13px;
  background: url("../../assets/img/phone-icon.png") no-repeat center center;
  background-size: 100% 100%;
  margin-right: 2px;
  vertical-align: middle;
}
.phone-success {
  // display: inline-block;
  // padding: 4px 10px;
  // background-color: #70b603;
  // color: #fff;
  // border-radius: 4px;
  span {
    color: #1AAEAD;
    display: inline-block;
    vertical-align: middle;
  }
}

.phone-error {
  // display: inline-block;
  // padding: 4px 10px;
  // background-color: #aaaaaa;
  // color: #fff;
  // border-radius: 4px;
  span {
    color: #788DB4;
    display: inline-block;
    vertical-align: middle;
  }
  .phone-icon-box {
    background: url("../../assets/img/phone-icon-error.png") no-repeat center center;
  }
}

.hover-dom {
  cursor: pointer;
}


</style>
