// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/phone-icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/phone-icon-error.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".phone-icon-box[data-v-6810560f] {\n  display: inline-block;\n  width: 12px;\n  height: 13px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  background-size: 100% 100%;\n  margin-right: 2px;\n  vertical-align: middle;\n}\n.phone-success span[data-v-6810560f] {\n  color: #1aaead;\n  display: inline-block;\n  vertical-align: middle;\n}\n.phone-error span[data-v-6810560f] {\n  color: #788db4;\n  display: inline-block;\n  vertical-align: middle;\n}\n.phone-error .phone-icon-box[data-v-6810560f] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center center;\n}\n.hover-dom[data-v-6810560f] {\n  cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
