<template>
  <div>
    <transition name="fade">
      <div class="mask" v-show="detailShow">
        <transition name="fade">
          <div class="detail">
            <div class="header">
              欠费详情
              <i class="el-icon-close" @click="$emit('close')" style="cursor: pointer"></i>
            </div>
            <div class="content">
              <div class="picWrapper">
                <el-table  v-loading="loading" :data="tableData" style="width: 100%">
                  <el-table-column
                    type="index"
                    :label="$t('list.index')"
                    width="70"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    v-for="item in tableCols"
                    :key="item.prop"
                    :prop="item.prop"
                    align="center"
                    :label="item.label"
                    :min-width="item.width"
                    :formatter="item.formatter"
                  >
                    <template slot-scope="scope">
                      <div v-if="item.type == 'img'">
                        <img
                          v-if="scope.row.pictureList && scope.row.pictureList[item.imgType]"
                          :src="scope.row.pictureList[item.imgType]"
                          class="img-box"
                          alt=""
                          @click="handleBigImg(scope.row.pictureList[item.imgType])"
                        />
                        <span v-else>无</span>
                      </div>
                      <div v-else>
                        <p>
                          {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <!--分页器-->
                <div class="pagerWrapper">
                  <div class="block">
                    <el-pagination
                      v-if="total != 0"
                      @current-change="handleCurrentChange"
                      :current-page="pageNum"
                      :page-size="pageSize"
                      layout="total, prev, pager, next, jumper"
                      :total="total"
                    ></el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    detailShow: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default: {},
    },
    cardIds: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bigPic: false,
      picUrl: "",
      loading: false,
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      tableCols: [
        {
          prop: "debtOrderId",
          label: this.$t("list.order_ID"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "100",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "money",
          label: this.$t("list.arrears"),
          width: "100",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2) + "元";
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "entryPhoto",
          label: "入场照片",
          type: "img",
          imgType: 0,
          width: "",
        },
        {
          prop: "exitPhoto",
          label: "出场照片",
          type: "img",
          imgType: 1,
          width: "",
        },
      ],
    };
  },
  created() {
    this.searchData();
  },
  methods: {
    handleBigImg(url) {
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    searchData() {
      this.loading = true;
      let params = {
        page: this.pageNum,
        pageSize: this.pageSize,
        entryExitType: 2,
      };
      if (this.cardIds) {
        params.carIds = this.cardIds;
      } else {
        params.carId = this.rowData.carId;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/debtOrder/list",
        data: params,
        success: (res) => {
          if (res.state == 0) {
            // this.tableData = res.value.list;
            this.$set(this, "tableData", res.value.list);
            this.total = res.value.total * 1 || 0;
            console.log(this.tableData);
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
  },
  watch: {
    detailShow(newVal, oldVal) {
      if (newVal) {
        this.pageNum = 1;
        this.searchData();
      }
    },
  },
};
</script>
<style media="screen">
.picWrapper .el-tabs__content {
  overflow-y: auto;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper {
  .el-pagination {
    text-align: right;
    padding-top: 20px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to { /* .fade-leave-active in <2.1.8 */
  opacity: 0;
}

.detail {
  width: 70%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .header {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .content {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;

    .picWrapper {
      .picBox {
        float: left;
        width: 33.33333%;
        padding: 0 20px 15px 0;
        box-sizing: border-box;
      }

      .title {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #8091A5;
        letter-spacing: 0;
        line-height: 16px;
      }

      .pic {
        // background url('car-pic.png') center no-repeat
        width: 100%;
        height: 150px;
        border: 1px solid #8091A5;
        margin-top: 10px;
        overflow: hidden;
      }
    }

    .infoWrapper {
      border-top: 1px solid #D3DCE6;
      padding-top: 20px;

      .title {
        height: 42px;
        line-height: 42px;
        color: #8391a5;
        font-size: 14px;
        width: 100%;
        border-bottom: 1px solid #d1dbe5;
        margin: 0 0 15px;
      }

      .info {
        font-size: 12px;
        color: #313541;
        letter-spacing: 0;
        line-height: 32px;
        overflow: hidden;

        .infoItem {
          width: 33.3333%;
          box-sizing: border-box;
          float: left;

          .label {
            width: 100px;
            text-align: right;
            display: inline-block;
          }
        }
      }
    }

    .footer {
      clear: both;
      text-align: center;
      margin-top: 50px;
    }
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

/deep/ {
  .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar { /* 滚动条整体样式 */
      width: 10px; /* 高宽分别对应横竖滚动条的尺寸 */
      height: 1px;
    }

    &::-webkit-scrollbar-thumb { /* 滚动条里面小方块 */
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }

    &::-webkit-scrollbar-track { /* 滚动条里面轨道 */
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #EDEDED;
    }
  }
}

.parkTitle {
  border-bottom: 1px solid #e1e1e1;
}

.parkDeatail {
  padding-left: 15px;
}

.parkInfo {
  border-bottom: 2px solid #e0e6ed;
  padding-bottom: 12px;
}

.parkingInfo {
  font-family: 'PingFang';
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  font-weight: 500;
}

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}

.parkinfoDeatail {
  display: flex;
  color: #566475;
}

.parkinfoDeatail div:first-child {
  width: 50%;
  height: 30px;
  line-height: 30px;
}

.parkinfoDeatail div:first-child > span:first-child {
  width: 100px;
  display: inline-block;
}

.parkinfoDeatail div:last-child {
  width: 50%;
  height: 30px;
  line-height: 30px;
}

.parkinfoDeatail div:last-child > span:first-child {
  width: 100px;
  display: inline-block;
}

.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}

.picTitle {
  line-height: 30px;
}

.detail1 {
  line-height: 25px;
}

.pic-title {
  padding-left: 15px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 900;
}

.img-box {
  width: 100%;
  height: 44px;
}
</style>
